import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, useTranslate, useNotify, TextInput, useRedirect, required } from 'react-admin';
import {
    Button,
    CardActions,
    CircularProgress,
    Card,
} from '@mui/material';
import Box from '@mui/material/Box';

export const RequestResetPassPage = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    
    const submit = values => {
        setLoading(true);

        const request = new Request('/api/v1/users/request_pass_reset', {
            method: 'POST',
            body: JSON.stringify({ 
                login: values.username, 
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        fetch(request)
            .then(response => {
                setLoading(false);
                if(response.status === 200) {
                    notify('misc.resetPassRequestOk', { type: 'info' });
                    redirect(`/login`);
                } else {
                    notify('misc.resetPassRequestFail', { type: 'warning' });
                }
            })
            .catch((error) => {
                setLoading(false);
                notify(typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'misc.resetPassRequestFail'
                    : error.message, { type: 'warning' });
            });
    };

    return (
        <Form onSubmit={submit} noValidate>
            <Box
                sx={{
                    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                source="username"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                        <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {loading && (
                                <CircularProgress
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('button.resetPass')}
                        </Button>
                        </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

RequestResetPassPage.propTypes = {
    redirectTo: PropTypes.string,
};
