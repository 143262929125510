import { List,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    SelectField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    SelectInput,
    TextInput,
    Show,
    SimpleShowLayout,
    useRecordContext,
    TopToolbar,
} from 'react-admin';
import { useMediaQuery} from '@mui/material';
import { JsonField } from "react-admin-json-view";
import { BackButton } from './components/Buttons'
import { Pagination500 } from './components/Pagination'

export const WEventTypeChoices = [
    { id: 1, name: 'weventtypes.id1' },
    { id: 2, name: 'weventtypes.id2' },
    { id: 3, name: 'weventtypes.id3' },
    { id: 4, name: 'weventtypes.id4' },
    { id: 5, name: 'weventtypes.id5' },
    { id: 6, name: 'weventtypes.id6' },
    { id: 7, name: 'weventtypes.id7' },
    { id: 8, name: 'weventtypes.id8' },
    { id: 9, name: 'weventtypes.id9' },
    { id: 10, name: 'weventtypes.id10' },
    { id: 11, name: 'weventtypes.id11' },
    { id: 12, name: 'weventtypes.id12' },
    { id: 28, name: 'weventtypes.id28' },
    { id: 29, name: 'weventtypes.id29' },
    { id: 30, name: 'weventtypes.id30' },
    { id: 31, name: 'weventtypes.id31' },
    { id: 33, name: 'weventtypes.id33' },
    { id: 40, name: 'weventtypes.id40' },
    { id: 1000, name: 'weventtypes.id1000' },
    { id: 1001, name: 'weventtypes.id1001' },
    { id: 1002, name: 'weventtypes.id1002' },
    { id: 1003, name: 'weventtypes.id1003' },
    { id: 1004, name: 'weventtypes.id1004' },
    { id: 1005, name: 'weventtypes.id1005' },
    { id: 1006, name: 'weventtypes.id1006' },
    { id: 1007, name: 'weventtypes.id1007' },
    { id: 1008, name: 'weventtypes.id1008' },
    { id: 1009, name: 'weventtypes.id1009' },
    { id: 1010, name: 'weventtypes.id1010' },
    { id: 1011, name: 'weventtypes.id1011' },
    { id: 1012, name: 'weventtypes.id1012' },
    { id: 1013, name: 'weventtypes.id1013' },
    { id: 1014, name: 'weventtypes.id1014' },
    { id: 1015, name: 'weventtypes.id1015' },
    { id: 1016, name: 'weventtypes.id1016' },
    { id: 1017, name: 'weventtypes.id1017' },
    { id: 1018, name: 'weventtypes.id1018' },
    { id: 1019, name: 'weventtypes.id1019' },
    { id: 1020, name: 'weventtypes.id1020' },
    { id: 1021, name: 'weventtypes.id1021' },
    { id: 1022, name: 'weventtypes.id1022' },
    { id: 1023, name: 'weventtypes.id1023' },
];

export const ObjectTypeChoices = [
    { id: 1, name: 'WObjectTypeUser' },
    { id: 2, name: 'WObjectTypeGroup' },
    { id: 1000, name: 'WObjectTypeContentProvider' },
    { id: 1001, name: 'WObjectTypeSmsCenter' },
    { id: 1002, name: 'WObjectTypeHandset' },
    { id: 1003, name: 'WObjectTypeSettings' },
];

export const EventList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <SelectField source="type" choices={WEventTypeChoices} sortable={false} basePath="wevents" record={record}/>}
                    secondaryText={record => `${translate('resources.wevents.fields.created_at')}: ${record.created_at}`}
                    tertiaryText={record => <ReferenceField source="w_user_id" reference="users" link={false} basePath="users" record={record}>
                        <TextField source="login" />
                    </ReferenceField>}
                />
            ) : (   
            <Datagrid bulkActionButtons={false} rowClick="show"> 
                <SelectField source="type" choices={WEventTypeChoices} sortable={false}/>
                <DateField source="created_at" locales="ru-RU" showTime />
                <ReferenceField source="w_user_id" reference="users" link={false}>
                    <TextField source="login" />
                </ReferenceField>      
                <ReferenceField source="w_session_id" reference="sessions" link={false} sortable={false}>
                    <TextField source="id" />
                </ReferenceField>               
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <SelectInput source="type" choices={WEventTypeChoices} />,
    <ReferenceInput source="w_user_id" reference="users" filterToQuery={searchText => ({ login: searchText })} >
        <SelectInput optionText="login" />
    </ReferenceInput>,
    <TextInput source="w_session_id" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
];

export const EventShow = () => {
    return <Show 
        title={<Title />}
        actions={<Buttons />}>
        <SimpleShowLayout >
            <TextField source="id" />
            <SelectField source="type" choices={WEventTypeChoices}/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <ReferenceField source="w_user_id" reference="users" link={false}>
                <TextField source="login" />
            </ReferenceField>      
            <ReferenceField source="w_session_id" reference="sessions" link={false} sortable={false}>
                <TextField source="id" />
            </ReferenceField>
            <SelectField source="object_type" choices={ObjectTypeChoices}/>
            <TextField source="object_ids" />
            <JsonField
                label={'resources.wevents.data'}
                source="details"
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleShowLayout>
    </Show>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.wevents.title")}{record ? `${record.id}` : ''}</span>;
};

const Buttons = () => (
    <TopToolbar>
        <BackButton/>
    </TopToolbar>
);