// value 3 is deleted which never appears on front
export const StatusChoices = [
    { id: 1, name: 'misc.active' },
    { id: 2, name: 'misc.inactive' },
];

export const NotActStatusChoices = [
    { id: 1, name: 'misc.active' },
    { id: 2, name: 'misc.inactive' },
    { id: 4, name: 'misc.notActivated' },
];

export const CpProtocolChoices = [
    { id: 1, name: 'misc.CpProtocol.CpGenericJson' },
];

export const ScProtocolChoices = [
    { id: 1, name: 'misc.ScProtocol.ScGenericJson' },
    { id: 2, name: 'misc.ScProtocol.ScTwin24' },
    { id: 3, name: 'misc.ScProtocol.ScSmsLabXML' },
    { id: 4, name: 'misc.ScProtocol.AndroidApp' },
];

export const DirectionChoices = [
    { id: 1, name: '--> MT' },
    { id: 2, name: '<-- MO' },
];

export const SmsStatusChoices = [
    { id: 1, name: 'misc.SmsStatus.NotSent' },
    { id: 2, name: 'misc.SmsStatus.DeliveryPending' },
    { id: 3, name: 'misc.SmsStatus.Delivered' },
    { id: 4, name: 'misc.SmsStatus.NotDelivered' },
    { id: 5, name: 'misc.SmsStatus.Expired' },
    { id: 6, name: 'misc.SmsStatus.Unknown' },
    { id: 7, name: 'misc.SmsStatus.Rejected' },
    { id: 8, name: 'misc.SmsStatus.NotRouted' },
    { id: 101, name: 'misc.SmsStatus.CpDeliveryPending' },
    { id: 102, name: 'misc.SmsStatus.DeliveredToCp' },
    { id: 103, name: 'misc.SmsStatus.NotDeliveredToCp' },
];

export const ScSendAndDeliveryChoices = [
    // SmsLab XML status code
    { id: 3005001, name: 'SMSLAB_RESPONSE_STATUS_OK' },
    { id: 3005002, name: 'SMSLAB_RESPONSE_STATUS_AUTH_ERROR' },
    { id: 3005003, name: 'SMSLAB_RESPONSE_STATUS_NO_BALANCE' },
    { id: 3005004, name: 'SMSLAB_RESPONSE_STATUS_ACC_BLOCKED' },
    { id: 3005005, name: 'SMSLAB_RESPONSE_STATUS_NO_MSISDN' },
    { id: 3005006, name: 'SMSLAB_RESPONSE_STATUS_MSISDN_IN_STOP' },
    { id: 3005007, name: 'SMSLAB_RESPONSE_STATUS_RANGE_CLOSED' },
    { id: 3005008, name: 'SMSLAB_RESPONSE_STATUS_RANGE_UNAVAILABLE' },
    { id: 3005009, name: 'SMSLAB_RESPONSE_STATUS_TEXT_BLOCKED' },
    { id: 3005010, name: 'SMSLAB_RESPONSE_STATUS_NO_SENDER' },
    { id: 3005011, name: 'SMSLAB_RESPONSE_STATUS_WRONG_SENDER' },
    { id: 3005012, name: 'SMSLAB_RESPONSE_STATUS_WRONG_MSISDN' },
    { id: 3005013, name: 'SMSLAB_RESPONSE_STATUS_NO_TEXT' },
    { id: 3005014, name: 'SMSLAB_RESPONSE_STATUS_SENDER_UNAVAILABLE' },
    { id: 3005015, name: 'SMSLAB_RESPONSE_STATUS_SENDER_PENDING' },
    { id: 3005016, name: 'SMSLAB_RESPONSE_STATUS_ENDED_SMS' },
    { id: 3005017, name: 'SMSLAB_RESPONSE_CERT_EXPIRED' },
    // SmsLab XML delivery code
    { id: 3006001, name: 'SMSLAB_DELIVERY_STATUS_DELIVERED' },
    { id: 3006002, name: 'SMSLAB_DELIVERY_STATUS_NOT_DELIVERED' },
    { id: 3006003, name: 'SMSLAB_DELIVERY_STATUS_EXPIRED' },
    { id: 3006004, name: 'SMSLAB_DELIVERY_STATUS_PARTLY_DELIVERED' },
    // Generic JSON SMS status code
    { id: 3007001, name: 'JSONSMS_RESPONSE_STATUS_OK' },
    { id: 3007002, name: 'JSONSMS_RESPONSE_STATUS_HTTP_400' },
    { id: 3007003, name: 'JSONSMS_RESPONSE_STATUS_HTTP_401' },
    { id: 3007004, name: 'JSONSMS_RESPONSE_STATUS_HTTP_415' },
    { id: 3007005, name: 'JSONSMS_RESPONSE_STATUS_HTTP_500' },
    { id: 3007006, name: 'JSONSMS_RESPONSE_STATUS_MT_SOURCE_NOT_VALID' },
    { id: 3007007, name: 'JSONSMS_RESPONSE_STATUS_MESSAGE_TEXT_EMPTY' },
    { id: 3007008, name: 'JSONSMS_RESPONSE_STATUS_TEXT_EXCEEDS_ALLOWED_LENGTH' },
    { id: 3007009, name: 'JSONSMS_RESPONSE_STATUS_MESSAGE_CANNOT_BE_SENT' },
    // Generic JSON SMS delivery code
    { id: 3008001, name: 'JSONSMS_DELIVERY_STATUS_DELIVERED' },
    { id: 3008002, name: 'JSONSMS_DELIVERY_STATUS_NOT_DELIVERED' },
    { id: 3008003, name: 'JSONSMS_DELIVERY_STATUS_EXPIRED' },
    //Twin24
    { id: 3009001, name: 'CREATED' },
    { id: 3009002, name: 'SENT' },
    { id: 3009003, name: 'PENDING' },
    { id: 3009004, name: 'DELAYED' },
    { id: 3009005, name: 'QUEUED' },
    { id: 3009006, name: 'SKIPPED' },
    { id: 3009007, name: 'DELIVERED' },
    { id: 3009008, name: 'UNDELIVERED' },
    { id: 3009009, name: 'PAUSED' },
    { id: 3009010, name: 'CANCELED' },
    { id: 3009011, name: 'ERROR' },
    //Android APP status code
    { id: 1, name: 'Sent successfully' },
    { id: 2, name: 'Send failure (generic)' },
    { id: 3, name: 'Send failure: no service' },
    { id: 4, name: 'Send failure: null PDU' },
    { id: 5, name: 'Send failure: radio off' },
    { id: 6, name: 'Send failure: limit exceeded' },
    { id: 7, name: 'Wrong mt_source' },
    //Android APP delivery code - intersection with status code, skipped    
];


