import { useState, React } from 'react';
import queryString from 'query-string'
import { Form, useTranslate, useNotify, useRedirect, TextInput } from 'react-admin';
import {
    Button,
    CardActions,
    CircularProgress,
    Card,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ClearStorage } from './utils';
import { useLocation } from "react-router-dom";
  
export const UserActivatePage = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    ClearStorage();
    const validate = (values) => {
        const errors = {};
        if ((!values.password) || (values.password === ""))
            errors.password = 'misc.required';
        if (values.password?.length < 6 ) 
            errors.password = 'resources.users.passShort';
        if (values.passwordRepeat === "")
            errors.passwordRepeat = 'misc.required';
        if (values.password !== values.passwordRepeat)
            errors.passwordRepeat = 'user.passDontMatchError';
        return errors;
    };
    const { search } = useLocation()
    const q = queryString.parse(search)
    
    const submit = values => {
        setLoading(true);
        const request = new Request('/api/v1/users/activate', {
            method: 'POST',
            body: JSON.stringify({ 
                activation_token: q.token,
                password: values.password, 
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        fetch(request)
            .then(response => {
                setLoading(false);
                if(response.status === 200) {
                    notify('misc.resetPassOk', { type: 'success' });
                    redirect(`/login`);
                } else if(response.status === 410) {
                    notify('misc.userActivateExpired', { type: 'warning' });
                } else {
                    notify('misc.resetPassFail', { type: 'warning' });
                }
            })
            .catch((error) => {
                setLoading(false);
                //console.log("catch: "+JSON.stringify(error));
                notify(typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'misc.resetPassFail'
                    : error.message, { type: 'warning' });
            });
    };

    return (
        <Form onSubmit={submit} validate={validate}>
            <Box
                sx={{
                    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                
                <Card sx={{ 
                        margin: '6rem 0 0 0',
                        minWidth: 300, }}>
                    <Box sx={{ margin: '1rem 1rem 0 1rem' }}>
                    <Typography variant="h6" sx={{ margin: '1rem 0 0 0' }}>
                        {translate('misc.userActivateText1')+q.login}
                    </Typography>
                    <Typography variant="body1" >
                        {translate('misc.userActivateText2')}
                    </Typography>
                    <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                type="password"
                                source="password"
                                label={translate('user.pass')}
                                disabled={loading}
                                fullWidth
                            />
                    </Box>
                    <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                type="password"
                                source="passwordRepeat"
                                label={translate('user.repeatPass')}
                                disabled={loading}
                                fullWidth
                            />
                    </Box>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {loading && (
                                <CircularProgress
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('button.setPass')}
                        </Button>
                    </CardActions>
                    </Box>
                </Card>
                
            </Box>
        </Form>
    );
};
