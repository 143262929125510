import { List, Datagrid, TextField, DateField, SimpleList, TextInput, DateTimeInput, BulkDeleteButton, Edit, SimpleForm, CheckboxGroupInput, Labeled, Create, Show, 
    SimpleShowLayout } from 'react-admin';
import * as Rights from './checkRights';
import { usePermissions, useTranslate, useRecordContext } from 'react-admin';
import { useMediaQuery, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { HistoryButtonToolbar } from './components/Toolbars'
import { Pagination500 } from './components/Pagination'

export const GroupList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    const customRowClick = (id, resource, record) => {
        // если группа с id === 1 запрещаем редактирование
        if (id === 1) {
            return "show";
        }
        if (Rights.check(permissions,Rights.EditUsersGroups)) {
            return "edit";
        } else {
            return "show";
        }
    };

    return (
        <List
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}                
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditUsersGroups) ? <DefaultBulkActionButtons /> : false } 
                rowClick={customRowClick}>
                <TextField source="name" />
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
                <TextField source="rights" sortable={false} />
            </Datagrid> 
            )}
        </List>
    )
};

const customFilter = [
    <TextInput source="name" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

const DefaultBulkActionButtons = props => {
    // если выбрана дефолт группа админа, скрываем кнопку удалить - группу нельзя удалять
    if (props.selectedIds.includes(1)) {
        return <></>    
    } else {
        return <>
            <BulkDeleteButton {...props} />
        </>
    }
};

export const GroupEdit = () => {
    const translate = useTranslate();
    return <Edit title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewUsersGroups}/>} mutationMode='pessimistic'>
        <SimpleForm validate={validateGroup}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <CheckboxGroupInput source="rights" row={false} choices={getChoices(translate)}/>
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.groups.title")}{record ? `${record.name}` : ''}</span>;
};

const validateGroup = values => {
    const errors = {};
    // это избыточно так как выше для группы админа даже не откроется edit view, но если юзер в строке браузера введет урл для редактирования, то валидация сработает
    if (values.id === 1) {
        errors.id = 'resources.groups.id1Error'
    }
    if (!values.rights || values.rights.length < 1) {
        errors.rights = 'resources.groups.rightsError';
    }
    if (!values.name) {
        errors.name = 'resources.groups.nameError';
    }
    return errors
};

const getChoices = (translate) => {
    let rv = [
        { id: 1, name: translate('rights.id1') },
        { id: 2, name: translate('rights.id2') },
        { id: 3, name: translate('rights.id3') },
        { id: 4, name: translate('rights.id4') },
        { id: 5, name: translate('rights.id5') },
    ];
    return rv;
};

export const GroupCreate = () => {
    const translate = useTranslate();
    return <Create redirect='list'>
        <SimpleForm validate={validateGroup}>
            <TextInput source="name" />
            <CheckboxGroupInput source="rights" row={false} choices={getChoices(translate)}/>
        </SimpleForm>
    </Create>
};

export const GroupShow = () => {
    return <Show title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewUsersGroups}/>}>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="name" />
            <RightsField source="rights"/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const RightsField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const translate = useTranslate();
    let rightsList = getChoices(translate)
    return <FormGroup row={false}>
        {rightsList.map((item, index) => (
            <FormControlLabel
                htmlFor={`${item.id}`}
                key={index}
                disabled={true}
                control={<Checkbox
                        key={index}
                        id={`${item.id}`}
                        color="primary"
                        checked={
                            record[source]
                                ? record[source].find(v => v === item.id) !== undefined
                                : false
                        }
                    />}
                label={item.name}
            />
        ))}
    </FormGroup>
};