export const ViewUsersGroups = 1;
export const EditUsersGroups = 2;
export const ViewMpEntities = 3;
export const EditMpEntities = 4;
export const ManageSms = 5;

export function check(userRights, right) {
    //console.log("checking right: ", right);
    if (userRights){
        for (let i = 0; i < userRights.length ; i++) {
            if (userRights[i] === right) {
            return true;
            }
        }
    }
    return false;
};