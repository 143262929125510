import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    usePermissions,
    useTranslate,
    MenuItemLink,
    useSidebarState,
} from 'react-admin';
import TuneIcon from '@mui/icons-material/Tune';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import SubMenu from './SubMenu';
import * as Rights from './checkRights';
import ViewListIcon from '@mui/icons-material/ViewList';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SmsIcon from '@mui/icons-material/Sms';
import BoltIcon from '@mui/icons-material/Bolt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PsychologyIcon from '@mui/icons-material/Psychology';

export const MyMenu = ({ dense = false }) => {
    const [state, setState] = useState({
        menuStats: true,
        menuSettings: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();
    const { permissions } = usePermissions();

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 300 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {(Rights.check(permissions,Rights.ViewMpEntities) 
                || Rights.check(permissions,Rights.ManageSms))
                 && <SubMenu
                handleToggle={() => handleToggle('menuStats')}
                isOpen={state.menuStats}
                name="menu.menuStats"
                icon={<PlayArrowIcon />}
                dense={dense}
            >
                {(Rights.check(permissions,Rights.ViewMpEntities) || Rights.check(permissions,Rights.ManageSms))
                    && <MenuItemLink
                    to="/smss"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.smss.menu')}
                    leftIcon={<SmsIcon />}
                    dense={dense}
                />}
            </SubMenu>}
            {(Rights.check(permissions,Rights.ViewUsersGroups) 
                || Rights.check(permissions,Rights.ViewMpEntities) // для cp\sc пунктов меню
                || Rights.check(permissions,Rights.ManageSms))  // для активации сценариев, но не их редактирования
                 && <SubMenu
                handleToggle={() => handleToggle('menuSettings')}
                isOpen={state.menuSettings}
                name="menu.menuSettings"
                icon={<TuneIcon />}
                dense={dense}
            >
                {(Rights.check(permissions,Rights.ViewMpEntities) || Rights.check(permissions,Rights.ManageSms))
                && <MenuItemLink
                    to="/settings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.settings.menu')}
                    leftIcon={<PsychologyIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewMpEntities) || Rights.check(permissions,Rights.ManageSms))
                && <MenuItemLink
                    to="/cps"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.cps.menu')}
                    leftIcon={<ContentPasteIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewMpEntities) || Rights.check(permissions,Rights.ManageSms))
                && <MenuItemLink
                    to="/scs"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.scs.menu')}
                    leftIcon={<BoltIcon  />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.users.menu')}
                    leftIcon={<PersonIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/groups"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.groups.menu')}
                    leftIcon={<GroupIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/sessions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.sessions.menu')}
                    leftIcon={<ViewListIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to={`/wevents`}
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.wevents.menu')}
                    leftIcon={<EventNoteIcon />}
                    dense={dense}
            /> }
            </SubMenu>}
        </Box>
    );
};

/*export const MyMenu = (props) => { 
    console.log("props: ", props);
    const resources = useResourceDefinitions()
    const [open] = useSidebarState();
    return (
        <Menu {...props}>
            
            {Object.keys(resources).map(name => (
                <MenuItemLink
                    key={name}
                    to={`/${name}`}
                    primaryText={
                        (resources[name].options && resources[name].options.label) ||
                        name
                    }
                    leftIcon={
                        resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
  
        </Menu>
    );
};*/