import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import englishMessages from 'ra-language-english';

const mp2Messages = {
    ru: {
        resources: {
            users: {
                menu: "Пользователи",
                name: "Пользователь |||| Пользователи",
                fields: {
                    login: "Логин",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    status: "Статус",
                    w_group_id: "Группа",
                    msisdn: "Телефон",
                    email: "Email",
                    first_name: "Имя",
                    middle_name: "Отчество",
                    last_name: "Фамилия",
                    locale: "Локаль",
                    password: "Пароль",
                    is_password_reset_required: "Требуется ли смена пароля",
                    activation_link: "Ссылка для активации",
                },
                loginError: "Логин обязательный параметр",
                groupError: "Группа обязательный параметр",
                passMissing: "Пароль обязательый параметр",
                statusMissing: "Статус обязательый параметр",
                passShort: "Пароль должен быть не менее 6 символов",
                localeError: "Локаль обязательный параметр",
                id1Error: "У администратора по умолчанию нельзя менять группу",
                id1StatusError: "У администратора по умолчанию нельзя менять статус",
                title: "Пользователь: ",
                loginExistsError: "Пользователь с таким логином уже существует",
            },
            groups: {
                menu: "Группы пользователей",
                name: "Группа пользователей |||| Группы пользователей",
                fields: {
                    name: "Название",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    rights: "Права",
                },
                rightsError: "Требуется установить хотя бы одно право",
                nameError: "Имя обязательный параметр",
                id1Error: "Группу администраторов по умолчанию нельзя редактировать",
                title: "Группа пользователей: ",
            },
            sessions: {
                menu: "Сессии пользователей",
                name: "Сессия пользователя |||| Сессии пользователей",
                fields: {
                    is_active: "Активна?",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    updated_count: "Число обращений",
                    updated_last: "Последнее действие",
                    w_user_id: "Пользователь",                    
                },
                closeOk: "Сессия закрыта |||| %{smart_count} сессий закрыто",
            },
            wevents: {
                menu: "События сессий",
                name: "событий |||| События сессий", // если по сущости нет событий истории, то отображается страница с сообщением "Нет событий"
                historyTitle: "История изменений объекта",
                fields: {
                    type: "Тип события",
                    created_at: "Время",
                    w_user_id: "Пользователь",
                    w_session_id: "Сессия",
                    object_type: "Тип сущности",
                    object_ids: "ID затронутых сущностей в рамках события",
                },
                title: "Событие: ",
                desc: "Детали события",
                data: "Дополнительные данные",
            },
            smss: {
                menu: "SMS-сообщения",
                name: "SMS-сообщение |||| SMS-сообщения",
                title: "SMS-сообщение: ",
                fields: {
                    direction: "Направление",
                    source: "От кого",
                    dest: "Кому",
                    msg_text: "Текст",
                    sms_status: "Статус доставки",
                    num_attempts_send: "Попытки отправки",
                    parts: "Частей",
                    sc_protocol: "Протокол СЦ",
                    cp_protocol: "Протокол КП",
                    last_attempt_send: "Посл. попытка отпр.",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    content_provider_id: "КП",
                    sms_center_id: "СЦ",
                    is_sent_delivery: "Отправлен ли отчет о доставке в КП",
                    last_attempt_send_delivery: "Посл. попытка отправки отчета о доставке",
                    num_attempts_send_delivery: "Попытки отправки отчета о доставке",
                    sc_result_status_code: "Статус отправки по данным СЦ",
                    sc_result_time: "Время отправки",
                    sc_delivery_status_code: "Статус доставки по данным СЦ",
                    sc_delivery_status_time: "Время получения отчета о доставке",
                    sc_delivery_time: "Время доставки по данным СЦ",
                    sc_msg_id: "ID сообщения по данным СЦ",
                    msg_id: "ID сообщения по данным MP",
                    source_mno_id: "Оператор отправителя",
                    dest_mno_id: "Оператор получателя",
                    is_sender_fixed: "Отправитель зафиксирован?",
                },
            },
            cps: {
                menu: "Контент-провайдеры",
                single: "Контент-провайдер",
                name: "Контент-провайдер |||| Контент-провайдеры",
                fields: {
                    name: "Название",
                    status: "Статус",
                    cp_protocol: "Протокол",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    tags: "Теги",
                },
                title: "Контент-провайдер: ",
                nameError: "Имя обязательный параметр",
                statusMissing: "Статус обязательый параметр",
                protocolMissing: "Протокол обязательный параметр",
                section_1: "Настройки Generic JSON SMS протокола (сервер)",
                DeliveryReceiptRetries: "Попытки отправки отчетов о доставке SMS",
                IncomingSmsRetries: "Попытки отправки входящих SMS",
                OurApikey: "Входящий API-ключ (наш)",
                TheirApikey: "Исходящий API-ключ (их)",
                TheirEndpoint: "URL контент-провайдера",
            },
            scs: {
                menu: "SMS-центры",
                name: "SMS-центр |||| SMS-центры",
                fields: {
                    name: "Название",
                    status: "Статус",
                    sc_protocol: "Протокол",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    tags: "Теги",
                },
                title: "SMS-центр: ",
                nameError: "Имя обязательный параметр",
                statusMissing: "Статус обязательый параметр",
                protocolMissing: "Протокол обязательный параметр",
                section_1: "Настройки Generic JSON SMS протокола (клиент)",
                section_2: "Настройки протокола Twin24",
                section_smslab: "Настройки протокола SmsLab XML",
                OutgoingSmsRetries: "Попытки отправки исходящих SMS",
                OurApikey: "Входящий API-ключ (наш)",
                TheirApikey: "Исходящий API-ключ (их)",
                TheirEndpoint: "URL SMS-центра",
                Twin24ApiURL: "URL Twin24",
                Twin24RefreshApiURL: "URL Twin24 to refresh auth token",
                Twin24InitialAuthToken: "Изначальный AuthToken Twin24",
                Twin24InitialRefreshToken: "Изначальный RefreshToken Twin24",
                runtime: "Runtime данные протокола",
                noRuntime: "У данного протокола нет рантайм данных",
                Twin24CurrentAuthToken: "Текущий AuthToken Twin24",
                Twin24CurrentRefreshToken: "Текущий RefreshToken Twin24",
                RefreshTime: "Время последнего обновления токенов",
                SmsLabApiURL: "URL SmsLab",
                SmsLabLogin: "Логин",
                SmsLabPass: "Пароль",
                SmsLabTrimSenderFirstChar: "Убрать первый символ mt_source",
                SmsLabDeliveryListenURL: "Ключ получения отчетов о доставке",
                SmsLabIncomingListenURL: "Ключ получения входящих SMS",
            },
            settings: {
                menu: "Сценарии маршрутизации",
                name: "Сценарий маршрутизации |||| Сценарии маршрутизации",
                fields: {
                    type: "Тип",
                    name: "Название",
                    is_active: "Активен?",
                    data: "Данные сценария",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                },
                title: "Сценарий: ",
                nameError: "Имя обязательный параметр",
                section_out: "Исходящие MT SMS-сообщения",
                section_inc: "Входящие MO SMS-сообщения и звонки",
                detectDestMno: "определять оператора для получателя (dest)",
                detectSourceMno: "определять оператора для отправителя (source)",
                rules: "Правила",
                anySc: "Любой СЦ",
                criteria: "Критерии срабатывания правила:",
                onRuleTrigger: "При срабатывании правила:",
                routeTo: "Маршрутизировать на",
            },
        },
        menu: {
            menuSettings: "Настройки",
            menuStats: "Статистика",
            missingRedirectRights: "Отсутствуют права для редиректа на прикладную страницу.",
        },
        misc: {
            resetPassRequestOk: "Если логин указан верно, вы получите email с инструкциями.",
            resetPassRequestFail: "Ошибка запроса на сброс пароля",
            resetPassOk: "Пароль успешно изменен",
            resetPassFail: "Ошибка обновления пароля, проверьте правильность ссылки",
            resetPassExpired: "Ссылка для смены пароля истекла",
            userActivateExpired: "Ссылка для активации пользователя истекла",
            userActivateText1: "Ваш логин для входа: ",
            userActivateText2: "Установите пароль для вашей учетной записи: ",
            setPassOk: "Пароль успешно установлен",
            active: "Активен",
            inactive: "Заблокирован",
            notActivated: "Не активирован",
            email: "Email",
            sms: "SMS",
            telegram: "Телеграм",
            monday: "Понедельник",
            tuesday: "Вторник",
            wednesday: "Среда",
            thursday: "Четверг",
            friday: "Пятница",
            saturday: "Суббота",
            sunday: "Воскресенье",
            start: "Начало",
            end: "Конец",
            CpProtocol: {
                CpGenericJson: "Generic JSON SMS",
            },
            ScProtocol: {
                ScGenericJson: "Generic JSON SMS",
                ScTwin24: "Twin24",
                ScSmsLabXML: "ScSmsLabXML",
                AndroidApp: "AndroidApp",
            },
            SmsStatus: {
                NotSent: "Не отправлено",
                DeliveryPending: "Ожидается доставка",
                Delivered: "Доставлено",
                NotDelivered: "Не доставлено",
                Expired: "Истекло",
                Unknown: "Неизвестно",
                Rejected: "Отклонено",
                NotRouted: "Не маршрутизировано",
                CpDeliveryPending: "Ожидается доставка КП",
                DeliveredToCp: "Доставлено КП",
                NotDeliveredToCp: "Не доставлено КП",
            },
            fieldMandatory: "Поле обязательно",
            noData: "Нет данных",
            OutgoingCriteriaChoices: {
                cp: "Id КП",
                mtSource: "mtSource",
                mtDest: "mtDest",
            },
            OutgoingRouteToChoices: {
                reject: "Отклонить",
                scList: "На список СЦ",
                scTags: "На СЦ с тегами",
            },
            type: "Тип",
            from: "От: ",
            to: "До: ",
            value: "Значение: ",
            predicate: "предикат",
            predicateList: "массив предикатов",
            PredicateTypeChoices: {
                and: "И",
                or: "ИЛИ",
                eq: "РАВНО",
                notEq: "НЕ РАВНО",
                inRange: "В диапазоне",
                notInRange: "НЕ в диапазоне",
            },
        },
        button: {
            configuration: "Личные настройки",
            changePass: "Сменить пароль",
            setPass: "Установить пароль",
            showHistory: "История изменений",
            resetPassLink: "Вспомнить пароль",
            resetPass: "Получить письмо",
            closeSession: "Закрыть",
            showSessions: "Сессии пользователя",
            downloadReport: "Скачать отчет",
            findInteractions: "Найти сообщения",
            requestFrame: "Скачать полное изображение",
            block: "Заблокировать",
            unblock: "Разблокировать",
            activate: "Активировать",
            ok: 'Сущность обработана |||| %{smart_count} сущностей обработано',
            fail: 'Сервер вернул ошибку: %{code}',
            unroute: "Снять маршрутизацию",
            route: "Маршрутизировать",
            reroute: "Перемаршрутизировать",
            copy: "Копировать",
        },
        filter: {
            tag: "Тег",
            created_at_from: "Время создания от...",
            created_at_to: "Время создания до...",
            updated_at_from: "Время обновления от...",
            updated_at_to: "Время обновления до...",
            last_send_attempt_at_from: "Время отправки от...",
            last_send_attempt_at_to: "Время отправки до...",
        },
        user: {
            settings: "Настройки пользователя",
            language: "Язык интерфейса",
            first_name: "Имя",
            middle_name: "Отчество",
            last_name: "Фамилия",
            msisdn: "Номер телефона",
            email: "Email",
            updateOk: "Настройки успешно обновлены",
            updateFail: "Ошибка обновления настроек",
            msisdnError: "Неверный формат номера",
            changePass: "Смена пароля",
            oldPass: "Текущий пароль",
            newPass: "Новый пароль",
            pass: "Пароль",
            repeatPass: "Повторите пароль",
            repeatNewPass: "Повторите новый пароль",
            changePassOk: "Пароль успешно обновлен",
            changePassFail: "Ошибка обновления пароля",
            passSameError: "Новый пароль должен отличаться от текущего",
            passDontMatchError: "Введенные пароли не совпадают",
            wrongPass: "Пароль указан неверно",
        },
        weventtypes: {
            id1: "Логин пользователя",
            id2: "Выход пользователя",
            id3: "Создание пользователя",
            id4: "Редактирование пользователя",
            id5: "Удаление пользователя",
            id6: "Блокировка пользователя",
            id7: "Разблокировка пользователя",
            id8: "Смена пароля пользователем",
            id9: "Создание группы пользователей",
            id10: "Редактирование группы пользователей",
            id11: "Удаление группы пользователей",
            id12: "Закрытие сеанса пользователя",
            
            id28: "Обновление пользователем своих данных",
            id29: "Запрос сброса пароля",
            id30: "Сброс пароля пользователем",
            id31: "Сессия пользователя истекла",
            id40: "Активация пользователя",
            
            id33: "Сессия принудительно закрыта",
            id1000: "Создание контент-провайдера",
            id1001: "Редактирование контент-провайдера",
            id1002: "Удаление контент-провайдера",
            id1003: "Блокировка контент-провайдера",
            id1004: "Разблокировка контент-провайдера",
            id1005: "Создание СМС-центра",
            id1006: "Редактирование СМС-центра",
            id1007: "Удаление СМС-центра",
            id1008: "Блокировка СМС-центра",
            id1009: "Разблокировка СМС-центра",
            id1010: "Создание трубки",
            id1011: "Редактирование трубки",
            id1012: "Удаление трубки",
            id1013: "Блокировка трубки",
            id1014: "Разблокировка трубки",
            id1015: "Создание сценария маршрутизации",
            id1016: "Редактирование сценария маршрутизации",
            id1017: "Удаление сценария маршрутизации",
            id1018: "Активация сценария маршрутизации",
            id1019: "Снятие маршрутизации SMS",
            id1020: "Маршрутизация SMS",
            id1021: "Перемаршрутизация SMS",
            id1022: "Копирование сценария маршрутизации",
            id1023: "Деактивация сценария маршрутизации",
        },
        rights: {
            id1: "Просмотр пользователей и групп",
            id2: "Редактирование пользователей и групп",
            id3: "Просмотр прикладных сущностей",
            id4: "Редактирование прикладных сущностей",
            id5: "Управление SMS-рассылкой",
        }
    },
    en: {
        resources: {
            users: {
                menu: "Users",
                name: "User |||| Users",
                fields: {
                    login: "Login",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    status: "Status",
                    w_group_id: "Group",
                    msisdn: "Phone",
                    email: "Email",
                    first_name: "Name",
                    middle_name: "Middle name",
                    last_name: "Last name",
                    locale: "Locale",
                    password: "Password",
                    is_password_reset_required: "Password change required",
                    activation_link: "User activation link",
                },
                loginError: "Login is required",
                groupError: "Group is required",
                passMissing: "Password is required",
                statusMissing: "Status is required",
                passShort: "Password length should be at least 6",
                localeError: "Locale is required",
                id1Error: "Cannot edit group for default admin",
                id1StatusError: "Cannot edit status for default admin",
                title: "User: ",
                loginExistsError: "User with provided login already exists",
            },
            groups: {
                menu: "User groups",
                name: "User group |||| User groups",
                fields: {
                    name: "Name",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    rights: "Rights",
                },
                rightsError: "At least 1 right is required",
                nameError: "Name is required",
                id1Error: "Default admin group cannot be edited",
                title: "User group: ",
            },
            sessions: {
                menu: "User sessions",
                name: "User session |||| User sessions",
                fields: {
                    is_active: "Active?",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    updated_count: "Updates count",
                    updated_last: "Last action",
                    w_user_id: "User",                    
                },
                closeOk: 'Session closed |||| %{smart_count} sessions closed',
            },
            wevents: {
                menu: "Session events",
                name: "Session event |||| Session events",
                historyTitle: "Change history",
                fields: {
                    type: "Event type",
                    created_at: "Timestamp",
                    w_user_id: "User",
                    w_session_id: "Session",
                    object_type: "Object type",
                    object_ids: "Object IDs affected by the event",
                },
                title: "Event: ",
                desc: "Event details",
                data: "Additional data",
            },
            smss: {
                menu: "SMS messages",
                name: "SMS message |||| SMS messages",
                title: "SMS message: ",
                fields: {
                    direction: "Direction",
                    source: "Source",
                    dest: "Destination",
                    msg_text: "Text",
                    sms_status: "Delivery status",
                    num_attempts_send: "Send attempts",
                    parts: "Parts",
                    sc_protocol: "SC protocol",
                    cp_protocol: "CP protocol",
                    last_attempt_send: "Last send attempt",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    content_provider_id: "CP",
                    sms_center_id: "SC",
                    is_sent_delivery: "Is delivery receipt sent to CP",
                    last_attempt_send_delivery: "Last attempt to send delivery receipt",
                    num_attempts_send_delivery: "Attempts to send delivery receipt",
                    sc_result_status_code: "Send status from SC",
                    sc_result_time: "Send status time",
                    sc_delivery_status_code: "Delivery status from SC",
                    sc_delivery_status_time: "Delivery status received at",
                    sc_delivery_time: "Delivery time",
                    sc_msg_id: "SC Msg ID",
                    msg_id: "MP Msg ID",
                    source_mno_id: "Source MNO",
                    dest_mno_id: "Destination MNO",
                    is_sender_fixed: "Is sender fixed",
                },
            },
            cps: {
                menu: "Content providers",
                single: "Content provider",
                name: "Content provider |||| Content providers",
                fields: {
                    name: "Name",
                    status: "Status",
                    cp_protocol: "Protocol",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    tags: "Теги",
                },
                title: "Content provider: ",
                nameError: "Name is required",
                statusMissing: "Status is required",
                protocolMissing: "Protocol is required",
                section_1: "Generic JSON SMS protocol settings (server-side)",
                DeliveryReceiptRetries: "Delivery receipt send retries",
                IncomingSmsRetries: "Incoming SMS send retries",
                OurApikey: "Our API-key",
                TheirApikey: "Their API key",
                TheirEndpoint: "Their endpoint URL",
            },
            scs: {
                menu: "SMS-centers",
                name: "SMS-center |||| SMS-centers",
                fields: {
                    name: "Name",
                    status: "Status",
                    sc_protocol: "Protocol",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    tags: "Теги",
                },
                title: "SMS-center: ",
                nameError: "Name is required",
                statusMissing: "Status is required",
                protocolMissing: "Protocol is required",
                section_1: "Generic JSON SMS protocol settings (client-side)",
                section_2: "Twin24 protocol settings",
                section_smslab: "SmsLab XML protocol settings",
                OutgoingSmsRetries: "Outgoing SMS send retries",
                OurApikey: "Our API-key",
                TheirApikey: "Their API key",
                TheirEndpoint: "Their endpoint URL",
                Twin24ApiURL: "Twin24 endpoint URL",
                Twin24RefreshApiURL: "URL Twin24 to refresh auth token",
                Twin24InitialAuthToken: "Initial Twin24 AuthToken",
                Twin24InitialRefreshToken: "Initial Twin24 RefreshToken",
                runtime: "Runtime protocol data",
                noRuntime: "Current protocol has no runtime data",
                Twin24CurrentAuthToken: "Current AuthToken Twin24",
                Twin24CurrentRefreshToken: "Current RefreshToken Twin24",
                RefreshTime: "Last tokens refresh time",
                SmsLabApiURL: "SmsLab endpoint URL",
                SmsLabLogin: "Login",
                SmsLabPass: "Password",
                SmsLabTrimSenderFirstChar: "Trim mt_source first char",
                SmsLabDeliveryListenURL: "URL part for delivery receipts",
                SmsLabIncomingListenURL: "URL part for incoming SMS",
            },
            settings: {
                menu: "Routing scenarios",
                name: "Routing scenario |||| Routing scenarios",
                fields: {
                    type: "Type",
                    name: "Name",
                    is_active: "Active?",
                    data: "Scenario data",
                    created_at: "Created at",
                    updated_at: "Updated at",
                },
                title: "Scenario: ",
                nameError: "Name is required",
                section_out: "Outgoing MT SMS",
                section_inc: "Incoming MO SMS and calls",
                detectDestMno: "Detect MNO for destination",
                detectSourceMno: "Detect MNO for source",
                rules: "Rules",
                anySc: "Any SC",
                criteria: "Rule criteria:",
                onRuleTrigger: "If triggered:",
                routeTo: "Route to",
            },
        },
        menu: {
            menuSettings: "Settings",
            menuStats: "Stats",
            missingRedirectRights: "Missing rights to redirect to target page.",
        },
        misc: {
            resetPassRequestOk: "If the provided login exists, you will receive an email.",
            resetPassRequestFail: "Error requesting password reset",
            resetPassOk: "Password changed successfully",
            resetPassFail: "Error changing password, did you use wrong URL?",
            resetPassExpired: "The link has expired",
            userActivateExpired: "User activation link has expired",
            userActivateText1: "Your login is: ",
            userActivateText2: "Set password for your account: ",
            setPassOk: "Password was set",
            active: "Active",
            inactive: "Blocked",
            notActivated: "Not activated",
            email: "Email",
            sms: "SMS",
            telegram: "Telegram",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            start: "Start",
            end: "End",
            CpProtocol: {
                CpGenericJson: "Generic JSON SMS",
            },
            ScProtocol: {
                ScGenericJson: "Generic JSON SMS",
                ScTwin24: "Twin24",
                ScSmsLabXML: "ScSmsLabXML",
                AndroidApp: "AndroidApp",
            },
            SmsStatus: {
                NotSent: "Not sent",
                DeliveryPending: "Delivery pending",
                Delivered: "Delivered",
                NotDelivered: "Not delivered",
                Expired: "Expired",
                Unknown: "Unknown",
                Rejected: "Rejected",
                NotRouted: "Not routed",
                CpDeliveryPending: "CP delivery pending",
                DeliveredToCp: "Delivered to CP",
                NotDeliveredToCp: "Not delivered to CP",
            },
            fieldMandatory: "Field is mandatory",
            noData: "No data",
            OutgoingCriteriaChoices: {
                cp: "CP ID",
                mtSource: "mtSource",
                mtDest: "mtDest",
            },
            OutgoingRouteToChoices: {
                reject: "Reject",
                scList: "To SC list",
                scTags: "To SCs with tags",
            },
            type: "Type",
            from: "From: ",
            to: "To: ",
            value: "Value: ",
            predicate: "predicate",
            predicateList: "predicate list",
            PredicateTypeChoices: {
                and: "AND",
                or: "OR",
                eq: "EQUAL",
                notEq: "NOT EQUAL",
                inRange: "In range",
                notInRange: "NOT In range",
            },
        },
        button: {
            configuration: "Personal settings",
            changePass: "Change password",
            setPass: "Set password",
            showHistory: "Change history",
            resetPassLink: "Reset pass",
            resetPass: "Get email with link",
            closeSession: "Close",
            showSessions: "User sessions",
            downloadReport: "Download report",
            findInteractions: "Find messages",
            requestFrame: "Download full image",
            block: "Block",
            unblock: "Unblock",
            activate: "Activate",
            ok: 'Entity processed |||| %{smart_count} entities processed',
            fail: 'Server returned error: %{code}',
            unroute: "Unroute",
            route: "Route",
            reroute: "Reroute",
            copy: "Copy",
        },
        filter: {
            tag: "Tag",
            created_at_from: "Created from...",
            created_at_to: "Created to...",
            updated_at_from: "Updated from...",
            updated_at_to: "Updated to...",
            last_send_attempt_at_from: "Send time from...",
            last_send_attempt_at_to: "Send time to...",
        },
        user: {
            settings: "User settings",
            language: "Interface language",
            first_name: "First name",
            middle_name: "Middle name",
            last_name: "Last name",
            msisdn: "Phone",
            email: "Email",
            updateOk: "Profile has been updated",
            updateFail: "Error updating profile",
            msisdnError: "Wrong phone number format",
            changePass: "Change password",
            oldPass: "Current password",
            newPass: "New password",
            pass: "Password",
            repeatPass: "Repeat password",
            repeatNewPass: "Repeat new password",
            changePassOk: "Password changed",
            changePassFail: "Password change unsuccessful",
            passSameError: "New password must be different",
            passDontMatchError: "Entered passwords do not match",
            wrongPass: "Wrong password",
        },
        weventtypes: {
            id1: "User login",
            id2: "User logout",
            id3: "Create user",
            id4: "Edit user",
            id5: "Delete user",
            id6: "Block user",
            id7: "Unblock user",
            id8: "User changed passsword",
            id9: "Create user group",
            id10: "Edit user group",
            id11: "Delete user group",
            id12: "Close user session",
           
            id28: "User self update",
            id29: "Reset password request",
            id30: "User reset password",
            id31: "User session expired",
            id40: "Activate user",

            id33: "Sesion closed",
            id1000: "Create content provider",
            id1001: "Edit content provider",
            id1002: "Delete content provider",
            id1003: "Block content provider",
            id1004: "Unblock content provider",
            id1005: "Create SMS-center",
            id1006: "Edit SMS-center",
            id1007: "Delete SMS-center",
            id1008: "Block SMS-center",
            id1009: "Unblock SMS-center",
            id1010: "Create handset",
            id1011: "Edit handset",
            id1012: "Delete handset",
            id1013: "Block handset",
            id1014: "Unblock handset",
            id1015: "Create routing scenario",
            id1016: "Edit routing scenario",
            id1017: "Delete routing scenario",
            id1018: "Activate routing scenario",
            id1019: "Unroute SMS",
            id1020: "Route SMS",
            id1021: "Reroute SMS",
            id1022: "Copy routing scenario",
            id1023: "Deactivate routing scenario",
        },
        rights: {
            id1: "View users and groups",
            id2: "Edit users and groups",
            id3: "View messaging entities",
            id4: "Edit messaging entities",
            id5: "Manage SMS delivery",
        }
    },
};

const messages = {
    ru: { ...russianMessages, ...mp2Messages.ru },
    en: { ...englishMessages, ...mp2Messages.en },
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], "ru");