import * as React from "react";
import { Admin, Resource, Layout, CustomRoutes, Authenticated } from 'react-admin';
import { GroupList, GroupEdit, GroupCreate, GroupShow } from './groups';
import { UserList, UserEdit, UserCreate, UserShow } from './users';
import { SessionList } from './sessions';
import { EventList, EventShow } from './events';
import { SmsList, SmsShow } from './smss';
import { CpList, CpEdit, CpCreate, CpShow } from './cps';
import { ScList, ScEdit, ScCreate, ScShow } from './scs';
import { SettingsList, SettingsEdit, SettingsCreate, SettingsShow } from './settings';
import jsonMp2Provider from './dataProvider';
import authProvider from './authProvider';
import { i18nProvider } from './i18n';
import * as Rights from './checkRights';
import { MyMenu } from './Menu';
import { MyAppBar } from './AppBar';
import { MyLoginPage } from './MyLoginPage';
import { defaultTheme } from 'react-admin';
import { RequestResetPassPage } from './RequestResetPass';
import { ResetPassPage } from './ResetPass';
import { Route } from 'react-router';
import { UserSettings } from './UserSettings';
import { ChangePass, MustChangePass } from './ChangePass';
import { DashboardRedirector } from './DashboardRedirector';
import { History } from './History';
import { UserActivatePage } from './UserActivate';

const MyLayout = (props) => {
    return <Layout {...props} 
    appBar={MyAppBar}
    menu={MyMenu} />;
};

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 300,
    },
};

const App = () => {
    //console.log('before Admin return');
    return <Admin 
        authProvider={authProvider}
        dataProvider={jsonMp2Provider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        theme={theme}
        loginPage={MyLoginPage}
        disableTelemetry
        dashboard={DashboardRedirector}
        >
        {permissions => [            
            <Resource name="users" 
                list={UserList}
                edit={Rights.check(permissions,Rights.EditUsersGroups) ? UserEdit : null}
                create={Rights.check(permissions,Rights.EditUsersGroups) ? UserCreate : null}
                show={Rights.check(permissions,Rights.ViewUsersGroups) ? UserShow : null}
            />,
            <Resource name="groups" 
                list={Rights.check(permissions,Rights.ViewUsersGroups) ? GroupList : null} 
                edit={Rights.check(permissions,Rights.EditUsersGroups) ? GroupEdit : null} 
                create={Rights.check(permissions,Rights.EditUsersGroups) ? GroupCreate : null}
                show={Rights.check(permissions,Rights.ViewUsersGroups) ? GroupShow : null}
            />,
            <Resource name="sessions" 
              list={Rights.check(permissions,Rights.ViewUsersGroups) ? SessionList : null} 
            />,
            <Resource name="wevents" 
              list={Rights.check(permissions,Rights.ViewUsersGroups) ? EventList : null} 
              show={Rights.check(permissions,Rights.ViewUsersGroups) ? EventShow : null} 
            />,

            <Resource name="smss" 
                list={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? SmsList : null}
                show={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? SmsShow : null}
            />,
            <Resource name="cps" 
                list={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? CpList : null} 
                edit={Rights.check(permissions,Rights.EditMpEntities) ? CpEdit : null} 
                create={Rights.check(permissions,Rights.EditMpEntities) ? CpCreate : null}
                show={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? CpShow : null}
            />,
            <Resource name="scs" 
                list={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? ScList : null} 
                edit={Rights.check(permissions,Rights.EditMpEntities) ? ScEdit : null} 
                create={Rights.check(permissions,Rights.EditMpEntities) ? ScCreate : null}
                show={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? ScShow : null}
            />,
            <Resource name="settings" 
                list={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? SettingsList : null} 
                edit={Rights.check(permissions,Rights.EditMpEntities) ? SettingsEdit : null} 
                create={Rights.check(permissions,Rights.EditMpEntities) ? SettingsCreate : null}
                show={(Rights.check(permissions,Rights.ViewMpEntities) || (Rights.check(permissions,Rights.ManageSms))) ? SettingsShow : null}
            />,
        ]}
        <CustomRoutes noLayout>
            <Route path="/requestresetpass" element={<RequestResetPassPage />} />
            <Route path="/resetpass/:token" element={<ResetPassPage />} />
            <Route path="/mustchangepass" element={<Authenticated><MustChangePass /></Authenticated>} />,
            <Route path="/activate" element={<UserActivatePage />} />, 
        </CustomRoutes>
        <CustomRoutes>
            <Route path="/configuration" element={<Authenticated><UserSettings /></Authenticated>} />,
            <Route path="/changepass" element={<Authenticated><ChangePass /></Authenticated>} />,
            <Route path="/history/:resource/:resource_id" element={<Authenticated><History /></Authenticated>} />,
        </CustomRoutes>
    </Admin>
    };

export default App;