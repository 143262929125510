import { ClearStorage } from './utils';

const authProvider = {
    // authentication
    login: ({ username, password }) =>  {
        //console.log('login');
        const request = new Request('/api/v1/users/login', {
            method: 'POST',
            body: JSON.stringify({ 
                login: username, 
                password: password,
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if(response.status !== 200) {
                    throw new Error('ra.auth.sign_in_error');
                }
                return response.json();
            })
            .then(data => {                
                setStorage(data);
                if (data?.w_user?.is_password_reset_required) {
                    return { redirectTo: "/mustchangepass" }
                }
            });
    },
    logout: () => {
        //console.log('logout');
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
            //console.log('logout returning without api call');
            return Promise.resolve();
        }
        const request = new Request('/api/v1/users/logout', {
            method: 'POST',
            headers: new Headers(
                { 'Authorization': `Bearer ${access_token}` },
            ),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return Promise.resolve();
            })
            .then(() => ClearStorage())
            .catch((err) => {
                //console.log(err)
                //throw err
            });
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403 || status === 406) {
            ClearStorage();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        if (localStorage.getItem('access_token')) {
            //console.log('checkAuth resolve');
            return Promise.resolve()
        } else {
            //console.log('checkAuth reject');
            return Promise.reject();
        }
    },
    getIdentity: () => {
        try {
            const { id, first_name, last_name, login } = JSON.parse(localStorage.getItem('w_user'));
            var fullName;
            if ((first_name === "") && (last_name === "")){
                fullName = login;
            } else {
                fullName = `${first_name} ${last_name}`;
            }
            //console.log('getIdentity resolve, with fullName: ', fullName);
            return Promise.resolve({ id, fullName });
        } catch (error) {
            //console.log('getIdentity reject, error: ', error);
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const permissions = localStorage.getItem('rights');
        if (permissions)  {
            //console.log('getPermissions return resolve');
            return Promise.resolve(JSON.parse(permissions))
        } else {
            //console.log('getPermissions return reject');
            return Promise.reject();
        }
    },
};

const setStorage = (data) => {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('expires', JSON.stringify(data.expires));
    localStorage.setItem('w_user', JSON.stringify(data.w_user));
    localStorage.setItem('rights', JSON.stringify(data.rights));
};

export default authProvider;
