import { useNavigate } from 'react-router';
import { Button, useTranslate } from 'react-admin'
import {
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'ra-core';
import { stringify } from 'query-string';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import LoopIcon from '@mui/icons-material/Loop';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { httpClient } from '../dataProvider';

export const BackButton = props => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const handleClick = () => {
       navigate(-1);
    };
    return (
        <Button 
            color="primary"
            label={translate('ra.action.back')}
            onClick={handleClick}
        >
            <ChevronLeftIcon />
        </Button>
    );
};

export const BlockButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.block')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/block?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <ClearIcon/>
        </Button>
};

export const UnblockButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.unblock')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/unblock?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok',
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <CheckIcon/>
        </Button>
};

// for SMS routing scenarios
export const ActivateButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.activate')}
            onClick={() => {
                const url = `/api/v1/${props.resource}/${props.selectedIds[0]}/activate`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <ToggleOnIcon/>
        </Button>
};

export const CopyButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.copy')}
            onClick={() => {
                const url = `/api/v1/${props.resource}/${props.selectedIds[0]}/copy`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <CopyAllIcon/>
        </Button>
};

// for sms list
export const UnrouteButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.unroute')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/unroute?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <DoNotDisturbIcon/>
        </Button>
};

export const RouteButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.route')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/route?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <RoundaboutRightIcon/>
        </Button>
};

export const RerouteButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.reroute')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/reroute?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <LoopIcon/>
        </Button>
};