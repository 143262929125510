import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';

export const httpClient = (url, options = {}) => {
    const access_token = localStorage.getItem('access_token');
    options.user = {
        authenticated: true,
        token: `Bearer ${access_token}`
    };
    return fetchUtils.fetchJson(url, options);
};

const jsonMp2Provider = {
    getList: (resource, params) => {
        //console.log("getList params: ", params)
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...params.filter,
            page: page,
            'per-page': perPage,
            order: order,
            'order-by': field,
        };
        const url = `/api/v1/${resource}?${stringify(query)}`;
        
        return httpClient(url)
        .then(({ status, headers, json }) => {
            return {
                data: json.items,
                total: json._meta.totalCount,
            };
        });
        /*.catch(e => {
            if (e instanceof HttpError && e.status === 404) {
                return Promise.reject(new HttpError('ra.page.not_found', e.status, e.body));
            }
            return Promise.reject(e);
        });*/
    },
    getOne: (resource, params) => {
        let url = `/api/v1/${resource}/${params.id}`
        return httpClient(url).then(({ json }) => ({
            data: json,
        })).catch(e => {
            //console.log(e);
            return Promise.reject(e);
        });
    },
    getMany: (resource, params) => {
        //console.log('getMany params: '+JSON.stringify(params));
        const query = {
            id: JSON.stringify(params.ids),
        };
        const url = `/api/v1/${resource}?${stringify(query)}`;
        return httpClient(url)
        .then(({ status, json }) => {
            if(status !== 200) {
                return Promise.reject();
            }
            return ({ data: json.items })
        })
        .catch(e => {
            //console.log(e);
            return Promise.reject(e);
        });
    },
    update: (resource, params) => {
        let url = `/api/v1/${resource}/${params.id}`
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(getUpdateJSON(resource, params.data)),
        }).then(({ json }) => ({ data: json }))
        .catch(e => {
            console.log(e);
            return Promise.reject(e);
        });
    },
    updateMany: null, 
    create: (resource, params) => {
        let url = `/api/v1/${resource}`
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })).catch(e => {
            //console.log(e);
            return Promise.reject(e);
        });
    },
    delete: (resource, params) => {
        let url = `/api/v1/${resource}/${params.id}`
        return httpClient(url, {
            method: 'DELETE',
        }).then(({ json }) => {
            return { data: json }
        })
    }, 
    deleteMany: (resource, params) => {
        const query = {
            id: JSON.stringify(params.ids),
        };
        const url = `/api/v1/${resource}?${stringify(query)}`;
        return httpClient(url, {
            method: 'DELETE',
        }).then(({ json }) => {
            return ({ data: json })   
        });
    },
    getManyReference: null,
};
function getUpdateJSON(resource, data) {
    if (resource === "groups") {
        return keepKeys(data, ['name','rights'])
    } 
    if (resource === "users") {
        return keepKeys(data, ['status','login','password','msisdn','email','first_name','middle_name','last_name','locale','w_group_id','is_password_reset_required'])
    }
    if (resource === "cps") {
        return keepKeys(data, ['name','status', 'cp_protocol', 'tags', 'protocol_settings'])
    }
    if (resource === "scs") {
        return keepKeys(data, ['name','status', 'sc_protocol', 'tags', 'protocol_settings'])
    }
    if (resource === "settings") {
        return keepKeys(data, ['name','data'])
    }
}
function keepKeys(obj, keys)
{
    var dup = {};
    for (var key in obj) {
        if (keys.indexOf(key) !== -1) {
            dup[key] = obj[key];
        }
    }
    return dup;
}

export default jsonMp2Provider;