import { List, Datagrid, TextField, DateField, SimpleList, TextInput, DateTimeInput, BulkDeleteButton, Edit, 
    SimpleForm, AutocompleteInput, Labeled, Create, Show, SimpleShowLayout, SelectField, SelectInput, FormDataConsumer, NumberInput, required,
    usePermissions, useTranslate, useRecordContext, NumberField, minValue, SingleFieldList, ChipField, FunctionField, ArrayField, AutocompleteArrayInput, BooleanInput } from 'react-admin';
import * as Rights from './checkRights';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { BlockButton, UnblockButton } from './components/Buttons'
import { ScProtocolChoices, NotActStatusChoices } from './Constants'
import { HistoryButtonToolbar } from './components/Toolbars'
import { useWatch } from "react-hook-form";
import { Pagination500 } from './components/Pagination'

export const ScList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}                
                />
            ) : (
            <Datagrid 
                bulkActionButtons={(Rights.check(permissions,Rights.EditMpEntities) || Rights.check(permissions,Rights.ManageSms)) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditMpEntities) ? "edit" : "show"}>
                <TextField source="id" />
                <TextField source="name" />
                <SelectField source="status" choices={NotActStatusChoices}/>
                <SelectField source="sc_protocol" choices={ScProtocolChoices}/>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
                <ArrayField source='tags'>
                    <SingleFieldList linkType={false}>
                        <FunctionField render={(record) => (
                            <ChipField record={{name: record }} source="name" />
                        )} />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid> 
            )}
        </List>
    )
};

const customFilter = [
    <TextInput source="name" />,
    <AutocompleteInput source="status" choices={NotActStatusChoices} />,
    <TextInput source="tags" label={'filter.tag'}/>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

const DefaultBulkActionButtons = props => {
    // TODO: здесь была идея сделать так чтобы кнопки были активы только в том случае если выделенные сущности позволяют сделать над ними действие.
    // то есть если выбран хоть один заблокированный СЦ, то кнопка заблокировать не появляется 
    // однако seletedIds передаются сквозным образом даже при листании страниц, а в data только то что на текущей странице, таким образом проверить статус всех 
    // выделенных сущностей нельзя, если они выделены на разных страницах. поэтому кнопки показываются всегда
    // что можно сделать: чтобы скрытие кнопок работало только в рамках одной страницы
    /*const { data } = useListContext();
    console.log("DefaultBulkActionButtons data: ", data)
    let blockButtonEnabled = true;
    let unblockButtonEnabled = true;
    for (let i = 0; i < props.selectedIds.length ; i++) {
        if (data[i].status !== 1) { // !== active
            blockButtonEnabled = false;
        }
        if (props.selectedIds[i].status !== 2) { // !== inactive
            unblockButtonEnabled = false;
        }
    }
    console.log("blockButtonEnabled: ", blockButtonEnabled, "unblockButtonEnabled: ", unblockButtonEnabled)
    */
    return <>
        <BlockButton {...props} />
        <UnblockButton {...props} />
        <BulkDeleteButton {...props} />
    </>
};

const TagsInput = () => {
    const tags = useWatch({ name: 'tags' });
    return <AutocompleteArrayInput source="tags"
            choices={tags ? tagsToChoices(tags) : []}
            disableClearable
            freeSolo
            translateChoice={false}
            onCreate={(newTagName) => {
                return { id: newTagName, name: newTagName };
            }}
        />
}

const tagsToChoices = items => items.map(item => ({ id: item, name: item }));

export const ScEdit = () => {
    const translate = useTranslate();
    return <Edit title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>} mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={[required('resources.scs.nameError')]}/>
            <SelectInput source="status" validate={[required('resources.scs.statusMissing')]} choices={NotActStatusChoices}/>
            <NumberInput defaultValue={5} label="resources.scs.OutgoingSmsRetries" source="protocol_settings.outgoingSmsRetries" validate={[required(), minValue(1)]}/>
            <SelectInput source="sc_protocol" validate={required('resources.scs.protocolMissing')} choices={ScProtocolChoices}/>
            <TagsInput/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return getProtocolElements(formData, translate)
                }}
            </FormDataConsumer>
            <RuntimeFields/>
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.scs.title")}{record ? `${record.name}` : ''}</span>;
};

export const ScCreate = () => {
    const translate = useTranslate();
    return <Create redirect='list'>
        <SimpleForm >
            <TextInput source="name" validate={[required('resources.scs.nameError')]} />
            <NumberInput defaultValue={5} label="resources.scs.OutgoingSmsRetries" source="protocol_settings.outgoingSmsRetries" validate={[required(), minValue(1)]}/>
            <SelectInput validate={[required('resources.scs.protocolMissing')]} source="sc_protocol" choices={ScProtocolChoices} />
            <TagsInput/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return getProtocolElements(formData, translate)
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
};

const getProtocolElements = (formData, translate) => {
    if(formData.sc_protocol === 1){
        if (formData.protocol_settings) {
            delete formData.protocol_settings.twin24;
            delete formData.protocol_settings.smsLab;
        }
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.scs.section_1')}
            </Typography>
            <TextInput fullWidth label="resources.scs.OurApikey" source="protocol_settings.genericJsonCli.ourApikey" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.TheirApikey" source="protocol_settings.genericJsonCli.theirApikey" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.TheirEndpoint" source="protocol_settings.genericJsonCli.theirEndpoint" validate={[required()]}/>
        </Box>
    } else if (formData.sc_protocol === 2) {
        if (formData.protocol_settings) {
            delete formData.protocol_settings.genericJsonCli;
            delete formData.protocol_settings.smsLab;
        }
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.scs.section_2')}
            </Typography>
            <TextInput fullWidth label="resources.scs.Twin24ApiURL" source="protocol_settings.twin24.twin24ApiUrl" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.Twin24RefreshApiURL" source="protocol_settings.twin24.twin24RefreshApiUrl" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.Twin24InitialAuthToken" source="protocol_settings.twin24.twin24InitialAuthToken" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.Twin24InitialRefreshToken" source="protocol_settings.twin24.twin24InitialRefreshToken" validate={[required()]}/>
        </Box>
    } else if (formData.sc_protocol === 3) {
        if (formData.protocol_settings) {
            delete formData.protocol_settings.genericJsonCli;
            delete formData.protocol_settings.twin24;
        }
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.scs.section_smslab')}
            </Typography>
            <TextInput fullWidth label="resources.scs.SmsLabApiURL" source="protocol_settings.smsLab.smsLabApiUrl" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.SmsLabLogin" source="protocol_settings.smsLab.smsLabLogin" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.SmsLabPass" source="protocol_settings.smsLab.smsLabPass" validate={[required()]}/>
            <BooleanInput fullWidth label="resources.scs.SmsLabTrimSenderFirstChar" source="protocol_settings.smsLab.smsLabTrimSenderFirstChar" />
            <TextInput fullWidth label="resources.scs.SmsLabDeliveryListenURL" source="protocol_settings.smsLab.smsLabDeliveryListenURL" validate={[required()]}/>
            <TextInput fullWidth label="resources.scs.SmsLabIncomingListenURL" source="protocol_settings.smsLab.smsLabIncomingListenURL" validate={[required()]}/>
        </Box>
    }
    return <></>
};

export const ScShow = () => {
    return <Show title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>}>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="name" />
            <SelectField source="status" choices={NotActStatusChoices}/>
            <NumberField fullWidth label="resources.scs.OutgoingSmsRetries" source="protocol_settings.outgoingSmsRetries" />
            <SelectField source="sc_protocol" choices={ScProtocolChoices}/>
            <ArrayField source='tags'>
                <SingleFieldList linkType={false}>
                    <FunctionField render={(record) => (
                        <ChipField record={{name: record }} source="name" />
                    )} />
                </SingleFieldList>
            </ArrayField>
            <ProtocolFields/>
            <RuntimeFields/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const ProtocolFields = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    //console.log("record: ", record);
    if(record.sc_protocol === 1){
        return <Box sx={{ p: '1em 1em 1em 1em', border: 1, width: 300, display: 'flex', 'flex-direction': 'column' }}>
        <Typography variant="h6" >
            {translate('resources.scs.section_1')}
        </Typography>
        <Labeled><TextField fullWidth label="resources.scs.OurApikey" source="protocol_settings.genericJsonCli.ourApikey"/></Labeled>
        <Labeled><TextField fullWidth label="resources.scs.TheirApikey" source="protocol_settings.genericJsonCli.theirApikey"/></Labeled>
        <Labeled><TextField fullWidth label="resources.scs.TheirEndpoint" source="protocol_settings.genericJsonCli.theirEndpoint"/></Labeled>
    </Box>
    } else if (record.sc_protocol === 2) {
        return <Box sx={{ p: '1em 1em 1em 1em', border: 1, width: 300, display: 'flex', 'flex-direction': 'column' }}>
        <Typography variant="h6" >
            {translate('resources.scs.section_2')}
        </Typography>
        <Labeled><TextField fullWidth label="resources.scs.Twin24ApiURL" source="protocol_settings.twin24.twin24ApiUrl"/></Labeled>
        <Labeled><TextField fullWidth label="resources.scs.Twin24RefreshApiURL" source="protocol_settings.twin24.twin24RefreshApiUrl"/></Labeled>
        <Labeled><TextField fullWidth label="resources.scs.Twin24InitialAuthToken" source="protocol_settings.twin24.twin24InitialAuthToken"/></Labeled>
        <Labeled><TextField fullWidth label="resources.scs.Twin24InitialRefreshToken" source="protocol_settings.twin24.twin24InitialRefreshToken"/></Labeled>
    </Box>
    }
};

const RuntimeFields = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    if((record.sc_protocol === 1) || (record.sc_protocol === 3)){
        return [
            <Box mt="1em" />,
            <Box sx={{ p: '1em 1em 1em 1em', border: 1, width: 300, display: 'flex', 'flex-direction': 'column' }}>
                <Typography variant="h6" >
                    {translate('resources.scs.runtime')}
                </Typography>
                {translate('resources.scs.noRuntime')}
            </Box>
        ]
    } else if (record.sc_protocol === 2) {
        return [
            <Box mt="1em" />,
            <Box sx={{ p: '1em 1em 1em 1em', border: 1, width: 300, display: 'flex', 'flex-direction': 'column' }}>
                <Typography variant="h6" >
                    {translate('resources.scs.runtime')}
                </Typography>
                <Labeled><TextField fullWidth emptyText={translate('misc.noData')} label="resources.scs.Twin24CurrentAuthToken" source="runtime_data.twin24.twin24CurrentAuthToken"/></Labeled>
                <Labeled><TextField fullWidth emptyText={translate('misc.noData')} label="resources.scs.Twin24CurrentRefreshToken" source="runtime_data.twin24.twin24CurrentRefreshToken"/></Labeled>
                <Labeled><DateField emptyText={translate('misc.noData')} label="resources.scs.RefreshTime" source="runtime_data.twin24.refreshTime" locales="ru-RU" showTime /></Labeled>
            </Box>
        ]
    }
}