import { List,
    NumberInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    SelectField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    SelectInput,
    TextInput,
    Show,
    SimpleShowLayout,
    useRecordContext,
    usePermissions,
    Pagination,
    useListContext,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import * as Rights from './checkRights';
import { UnrouteButton, RouteButton, RerouteButton } from './components/Buttons'
import { CpProtocolChoices, SmsStatusChoices, DirectionChoices, ScProtocolChoices, ScSendAndDeliveryChoices } from './Constants'
import { BackButtonToolbar } from './components/Toolbars'
import { Pagination500 } from './components/Pagination'

export const SmsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    linkType={"show"}
                    primaryText={record => <TextField source="msg_text" sortable={false} record={record}/>}
                    secondaryText={record => `${translate('resources.smss.fields.created_at')}: ${record.created_at}`}
                    tertiaryText={record => <SelectField source="sms_status" choices={SmsStatusChoices} record={record}/>}
                />
            ) : (   
            <Datagrid 
                bulkActionButtons={(Rights.check(permissions,Rights.EditMpEntities) || Rights.check(permissions,Rights.ManageSms)) ? <BulkActionButtons /> : false }
                rowClick="show"
                sx={{
                    '& .column-msg_text': { width: '30em' },
                }}
                > 
                <TextField source="id" />
                <SelectField source="direction" choices={DirectionChoices}/>
                <TextField source="source" />
                <TextField source="dest" />
                <DateField source="created_at" locales="ru-RU" showTime />
                <TextField source="msg_text" />
                <SelectField source="sms_status" choices={SmsStatusChoices}/>
                <ReferenceField emptyText="-" source="content_provider_id" reference="cps" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText="-" source="sms_center_id" reference="scs" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="num_attempts_send" />
                <TextField source="parts" />
                <SelectField emptyText="-" source="sc_protocol" choices={ScProtocolChoices}/>
            </Datagrid> )}
        </List>
    )
};

const MyPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25, 100]} {...props} />
);

const BulkActionButtons = props => {
    // скрытие кнопок работает только в рамках одной страницы
    //let t1 = Date.now()
    const { data } = useListContext();
    //console.log("DefaultBulkActionButtons data: ", data)
    let unrouteEnabled = true;
    let routeEnabled = true;
    for (let i = 0; i < data.length ; i++) {
        if (props.selectedIds.includes(data[i].id)) {
            //console.log("props.selectedIds: ", props.selectedIds, "data[i].id: ", data[i].id);
            if (data[i].sms_status !== 1) { //NotSent
                unrouteEnabled = false
            }
            if (data[i].sms_status !== 8) { //NotRouted
                routeEnabled = false
            }
            if (!unrouteEnabled && !routeEnabled) {
                break;
            }
        }
    }
    //let t2 = Math.abs(t1 - Date.now());
    //console.log("BulkActionButtons in ", t2);
    return <>
        {unrouteEnabled ? <UnrouteButton {...props} /> : null}
        {routeEnabled ? <RouteButton {...props} /> : null}
        {unrouteEnabled ? <RerouteButton {...props} /> : null}
    </>
};

const customFilter = [
    <TextInput source="id" />,
    <SelectInput source="direction" choices={DirectionChoices} />,
    <TextInput source="source" />,
    <TextInput source="dest" />,
    <SelectInput source="sms_status" choices={SmsStatusChoices} />,
    <TextInput source="msg_text" />,
    <NumberInput source="parts" />,
    <SelectInput source="sc_protocol" choices={ScProtocolChoices} />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

export const SmsShow = () => {
    return <Show title={<Title />} actions={<BackButtonToolbar />}>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="msg_id" />
            <TextField emptyText="-" source="sc_msg_id" />
            <SelectField source="direction" choices={DirectionChoices}/>
            <TextField source="source" />
            <TextField source="dest" />
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
            <TextField source="msg_text" />
            <SelectField source="sms_status" choices={SmsStatusChoices}/>
            <ReferenceField emptyText="-" source="content_provider_id" reference="cps" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField emptyText="-" source="sms_center_id" reference="scs" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="num_attempts_send" />
            <TextField source="parts" />
            <SelectField emptyText="-" source="sc_protocol" choices={ScProtocolChoices}/>
            <SelectField emptyText="-" source="cp_protocol" choices={CpProtocolChoices}/>
            <TextField emptyText="-" source="is_sent_delivery" />
            <DateField emptyText="-" source="last_attempt_send_delivery" locales="ru-RU" showTime />
            <TextField emptyText="-" source="num_attempts_send_delivery" />
            <SelectField emptyText="-" source="sc_result_status_code" choices={ScSendAndDeliveryChoices}/> 
            <DateField emptyText="-" source="sc_result_time" locales="ru-RU" showTime />
            <SelectField emptyText="-" source="sc_delivery_status_code" choices={ScSendAndDeliveryChoices}/>
            <DateField emptyText="-" source="sc_delivery_status_time" locales="ru-RU" showTime />
            <DateField emptyText="-" source="sc_delivery_time" locales="ru-RU" showTime />
            <TextField emptyText="-" source="source_mno_id" />
            <TextField emptyText="-" source="dest_mno_id" />
            <TextField emptyText="-" source="is_sender_fixed" />
            
        </SimpleShowLayout>
    </Show>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.smss.title")}{record ? `${record.id}` : ''}</span>;
};

