import { List, Datagrid, TextField, DateField, SimpleList, TextInput, DateTimeInput, BulkDeleteButton, Edit, 
    SimpleForm, AutocompleteInput, Labeled, Create, Show, SimpleShowLayout, SelectField, SelectInput, FormDataConsumer, NumberInput, required,
    usePermissions, useTranslate, useRecordContext, NumberField, SingleFieldList, ChipField, FunctionField, ArrayField, AutocompleteArrayInput } from 'react-admin';
import * as Rights from './checkRights';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { HistoryButtonToolbar } from './components/Toolbars'
import { BlockButton, UnblockButton } from './components/Buttons'
import { CpProtocolChoices, StatusChoices } from './Constants'
import { useWatch } from "react-hook-form";
import { Pagination500 } from './components/Pagination'


export const CpList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    return (
        <List
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}                
                />
            ) : (
            <Datagrid 
                bulkActionButtons={(Rights.check(permissions,Rights.EditMpEntities) || Rights.check(permissions,Rights.ManageSms)) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditMpEntities) ? "edit" : "show"}>
                <TextField source="id" />
                <TextField source="name" />
                <SelectField source="status" choices={StatusChoices}/>
                <SelectField source="cp_protocol" choices={CpProtocolChoices}/>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
                <ArrayField source='tags'>
                    <SingleFieldList linkType={false}>
                        <FunctionField render={(record) => (
                            <ChipField record={{name: record }} source="name" />
                        )} />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid> 
            )}
        </List>
    )
};

const customFilter = [
    <TextInput source="name" />,
    <AutocompleteInput source="status" choices={StatusChoices} />,
    <TextInput source="tags" label={'filter.tag'}/>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

const DefaultBulkActionButtons = props => {
    return <>
        <BlockButton {...props} />
        <UnblockButton {...props} />
        <BulkDeleteButton {...props} />
    </>
};

const TagsInput = () => {
    const tags = useWatch({ name: 'tags' });
    return <AutocompleteArrayInput source="tags"
            choices={tags ? tagsToChoices(tags) : []}
            disableClearable
            translateChoice={false}
            onCreate={(newTagName) => {
                return { id: newTagName, name: newTagName };
            }}
        />
}

const tagsToChoices = items => items.map(item => ({ id: item, name: item }));

export const CpEdit = () => {
    const translate = useTranslate();
    return <Edit title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>} mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={[required('resources.cps.nameError')]}/>
            <SelectInput source="status" validate={[required('resources.cps.statusMissing')]} choices={StatusChoices}/>
            <SelectInput disabled source="cp_protocol" choices={CpProtocolChoices}/>
            <TagsInput/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return getProtocolElements(formData, translate)
                }}
            </FormDataConsumer>
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.cps.title")}{record ? `${record.name}` : ''}</span>;
};

export const CpCreate = () => {
    const translate = useTranslate();
    return <Create redirect='list'>
        <SimpleForm >
            <TextInput source="name" validate={[required('resources.cps.nameError')]} />
            <SelectInput disabled defaultValue={1} validate={[required('resources.cps.protocolMissing')]} source="cp_protocol" choices={CpProtocolChoices} />
            <TagsInput/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return getProtocolElements(formData, translate)
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
};

const getProtocolElements = (formData, translate) => {
    if(formData.cp_protocol === 1){
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.cps.section_1')}
            </Typography>
            <NumberInput fullWidth defaultValue={5} label="resources.cps.DeliveryReceiptRetries" source="protocol_settings.genericJsonSrv.deliveryReceiptRetries" />
            <NumberInput fullWidth defaultValue={5} label="resources.cps.IncomingSmsRetries" source="protocol_settings.genericJsonSrv.incomingSmsRetries" />
            <TextInput fullWidth label="resources.cps.OurApikey" source="protocol_settings.genericJsonSrv.ourApikey" validate={[required()]}/>
            <TextInput fullWidth label="resources.cps.TheirApikey" source="protocol_settings.genericJsonSrv.theirApikey" validate={[required()]}/>
            <TextInput fullWidth label="resources.cps.TheirEndpoint" source="protocol_settings.genericJsonSrv.theirEndpoint" validate={[required()]}/>
        </Box>
    }
    return <></>
};

export const CpShow = () => {
    return <Show title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>}>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="name" />
            <SelectField source="status" choices={StatusChoices}/>
            <SelectField source="cp_protocol" choices={CpProtocolChoices}/>
            <ArrayField source='tags'>
                <SingleFieldList linkType={false}>
                    <FunctionField render={(record) => (
                        <ChipField record={{name: record }} source="name" />
                    )} />
                </SingleFieldList>
            </ArrayField>
            <ProtocolFields/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const ProtocolFields = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    //console.log("record: ", record);
    if(record.cp_protocol === 1){
        return <Box sx={{ p: '1em 1em 1em 1em', border: 1, width: 300, display: 'flex', 'flex-direction': 'column' }}>
        <Typography variant="h6" >
            {translate('resources.cps.section_1')}
        </Typography>
        <Labeled><NumberField fullWidth label="resources.cps.DeliveryReceiptRetries" source="protocol_settings.genericJsonSrv.deliveryReceiptRetries" /></Labeled>
        <Labeled><NumberField label="resources.cps.IncomingSmsRetries" source="protocol_settings.genericJsonSrv.incomingSmsRetries" /></Labeled>
        <Labeled><TextField fullWidth label="resources.cps.OurApikey" source="protocol_settings.genericJsonSrv.ourApikey"/></Labeled>
        <Labeled><TextField fullWidth label="resources.cps.TheirApikey" source="protocol_settings.genericJsonSrv.theirApikey"/></Labeled>
        <Labeled><TextField fullWidth label="resources.cps.TheirEndpoint" source="protocol_settings.genericJsonSrv.theirEndpoint"/></Labeled>
    </Box>
    }
};