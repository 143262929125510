import { List, Datagrid, TextField, DateField, SimpleList, TextInput, DateTimeInput, Edit, 
    SimpleForm, Labeled, Create, Show, SimpleShowLayout, SelectField, SelectInput, FormDataConsumer, useListContext, required, ArrayField, ReferenceArrayField, SingleFieldList, ReferenceField,
    usePermissions, useTranslate, useRecordContext, ReferenceInput, SimpleFormIterator, ReferenceArrayInput, SelectArrayInput, ArrayInput, BooleanField, AddItemButton, RemoveItemButton, BooleanInput, ChipField } from 'react-admin';
import * as Rights from './checkRights';
import { useMediaQuery, Box, Typography, Grid } from '@mui/material';
import { HistoryButtonToolbar } from './components/Toolbars'
import { ActivateButton, CopyButton } from './components/Buttons'
import { useWatch } from "react-hook-form";
import { Pagination500 } from './components/Pagination'

export const SettingsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    return (
        <List
            exporter={false}
            filter={{ stype: "sms_scenario" }}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}                
                />
            ) : (
            <Datagrid 
                bulkActionButtons={(Rights.check(permissions,Rights.EditMpEntities) || Rights.check(permissions,Rights.ManageSms)) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditMpEntities) ? "edit" : "show"}>
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="is_active" />
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Datagrid> 
            )}
        </List>
    )
};

const customFilter = [
    <TextInput source="name" />,
    <BooleanInput source="is_active" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

const DefaultBulkActionButtons = props => {
    const { data } = useListContext();
    //console.log("data: ", data, " selectedIds: ", props.selectedIds);
    // рисуем кнопку активировать только если выделены 1 настройки...
    let showActivate = (props.selectedIds.length < 2);
    if (!showActivate) {return <></>}
    // ... и они не активны
    for (let i = 0; i < data.length ; i++) {
        if (props.selectedIds.includes(data[i].id) && data[i].is_active) {
            showActivate = false;
            break;
        }
    }
    // рисуем кнопку копировать только если выделены 1 настройки...
    let showCopy = (props.selectedIds.length < 2);
    return <>
        { showActivate ? <ActivateButton {...props} /> : null}
        { showCopy ? <CopyButton {...props} /> : null}
    </>
};
//------------
export const SettingsEdit = () => {
    return <Edit title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>} mutationMode='pessimistic' transform={(data) => cleanupData(data)}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={[required('resources.settings.nameError')]} />
            <DataElements/>
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.settings.title")}{record ? `${record.name}` : ''}</span>;
};

export const SettingsCreate = () => {
    return <Create redirect='list' 
            transform={(data) => cleanupData(data)}>
        <SimpleForm defaultValues={() => ({ type: "sms_scenario" })}>
            <TextInput source="name" validate={[required('resources.settings.nameError')]} />
            <DataElements/>
        </SimpleForm>
    </Create>
};

const cleanupData = (data) => {
    //console.log("transform, data: ", data)
    // очищаем criteria.scIds у каждого правила входящих если выставлен флаг criteria.anySc
    if (data?.data?.routingSettings?.incoming?.rules) {
        for (let i = 0; i < data.data.routingSettings.incoming.rules.length ; i++) {
            //console.log("transform ", i, " data.routingSettings.incoming.rules[i]: ", data.data.routingSettings.incoming.rules[i])
            if (data.data.routingSettings.incoming.rules[i]?.criteria?.anySc) {
                delete data.data.routingSettings.incoming.rules[i].criteria.scIds;
            }
        }
    }
    if (data?.data?.routingSettings?.outgoing?.rules) {
        // очищаем routeTo у исходящих правил
        for (let i = 0; i < data.data.routingSettings.outgoing.rules.length ; i++) {
            let rule = data.data.routingSettings.outgoing.rules[i];
            if (rule?.routeTo?.type === "reject") {
                delete data.data.routingSettings.outgoing.rules[i].routeTo.scIds;
                delete data.data.routingSettings.outgoing.rules[i].routeTo.predicate;
            } else if (rule?.routeTo?.type === "scList") {
                delete data.data.routingSettings.outgoing.rules[i].routeTo.predicate;
            } else if (rule?.routeTo?.type === "scTags") {
                delete data.data.routingSettings.outgoing.rules[i].routeTo.scIds;
                // очищаем предикат
                cleanupPredicate(data.data.routingSettings.outgoing.rules[i].routeTo.predicate)
            }

            //очищаем предикаты у критериев
            for (let j = 0; j < rule.criteria.length ; j++) {
                cleanupPredicate(data.data.routingSettings.outgoing.rules[i].criteria[j].predicate);
            }
        }
    }
    return data
}

const cleanupPredicate = (predicate) => {
    //console.log("cleanupPredicate: ", predicate)
    if ((predicate.type === "and") || (predicate.type === "or")) {
        delete predicate.args;
        for (let i = 0; i < predicate.prArgs.length ; i++) {
            cleanupPredicate(predicate.prArgs[i]);
        }
    } else {
        delete predicate.prArgs;
    }
}

const DataElements = () => {
    const translate = useTranslate();
    return <>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.settings.section_out')}
            </Typography>
            <BooleanInput fullWidth label="resources.settings.detectDestMno" source="data.routingSettings.outgoing.detectDestMno" />
            <BooleanInput fullWidth label="resources.settings.detectSourceMno" source="data.routingSettings.outgoing.detectSourceMno" />
            <ArrayInput label="resources.settings.rules" source="data.routingSettings.outgoing.rules">
                <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
                    <FormDataConsumer>
                        {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                        }) => {
                            //console.log("FormDataConsumer scopedFormData: ", scopedFormData);
                            return <>
                                <Grid container width={{ xs: '100%' }} spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{translate('resources.settings.criteria')}</Typography>
                                        <CriteriaArray source={getSource("criteria")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{translate('resources.settings.onRuleTrigger')}</Typography>
                                        <SelectInput fullWidth source={getSource("routeTo.type")} choices={OutgoingRouteToChoices} label="resources.settings.routeTo" validate={[required()]}/>
                                        {scopedFormData?.routeTo?.type === "scList" ? (
                                        <ReferenceArrayInput source={getSource('routeTo.scIds')} reference="scs">
                                            <SelectArrayInput fullWidth label="resources.scs.menu">
                                                <ChipField source="name" />
                                            </SelectArrayInput>
                                        </ReferenceArrayInput> ) : null }
                                        {scopedFormData?.routeTo?.type === "scTags" ? 
                                        <Predicate source={getSource('routeTo.predicate')}/> : null }
                                    </Grid>
                                </Grid>
                            </>
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
        <Box p='1em 1em 1em 1em' ></Box>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.settings.section_inc')}
            </Typography>
            <BooleanInput fullWidth label="resources.settings.detectDestMno" source="data.routingSettings.incoming.detectDestMno" />
            <BooleanInput fullWidth label="resources.settings.detectSourceMno" source="data.routingSettings.incoming.detectSourceMno" />
            <ArrayInput label="resources.settings.rules" source="data.routingSettings.incoming.rules">
                <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
                    <FormDataConsumer>
                        {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                        }) => {
                            return <>
                                <Grid container width={{ xs: '100%' }} spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1">{translate('resources.settings.criteria')}</Typography>
                                        <BooleanInput fullWidth label="resources.settings.anySc" source={getSource('criteria.anySc')} />
                                        {!scopedFormData?.criteria?.anySc ? (
                                        <ReferenceArrayInput source={getSource('criteria.scIds')} reference="scs">
                                            <SelectArrayInput label="resources.scs.menu">
                                                <ChipField source="name" />
                                            </SelectArrayInput>
                                        </ReferenceArrayInput> ) : null }
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1">{translate('resources.settings.onRuleTrigger')}</Typography>
                                        <ReferenceInput source={getSource('routeToCp')} reference="cps">
                                            <SelectInput label="resources.cps.single" optionText="name" validate={[required()]}/>
                                        </ReferenceInput>
                                    </Grid>
                                </Grid>
                            </>                                
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </>
};

const CriteriaArray = (props) => {
    const {
        source,
        ...rest 
    } = props
    return <ArrayInput label={false} source={source}>
        <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
            <SelectInput fullWidth source="type" choices={OutgoingCriteriaChoices} label="misc.type" validate={[required()]}/>
            <Predicate source='predicate'/>
        </SimpleFormIterator>
    </ArrayInput>
}

const CriteriaArrayField = (props) => {
    const {
        source,
        ...rest 
    } = props
    return <ArrayField label={false} source={source}>
        <Datagrid bulkActionButtons={false}>
            <SelectField fullWidth source="type" choices={OutgoingCriteriaChoices} label="misc.type" validate={[required()]}/>
            <PredicateShow source='predicate'/>
        </Datagrid>
    </ArrayField>
}

const get = (from, selector) =>
  selector
      .replace(/\[([^\[\]]*)\]/g, '.$1.')
      .split('.')
      .filter(t => t !== '')
      .reduce((prev, cur) => prev && prev[cur], from);

const PredicateShow = (props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    let {
        source,
        ...rest 
    } = props
    if(!source) {
        source = "";
    }
    const prType = get(record,`${source}.type`);
    //console.log("record: ", record, "prType: ", prType, "source: ", source);
    return <Box 
        p='0.2em 0.2em 0.2em 0.2em' 
        border={1} borderColor="gray">
            <Typography variant="body2">{translate('misc.predicate')}</Typography>
            {// здесь и ниже криво дублируем код в зависимости от того определен ли source. от него зависит как задаем параметр source, с точкой или без
                (source) ? (<Labeled><SelectField fullWidth source={`${source}.type`} choices={PredicateTypeChoices} label="misc.type" /></Labeled>) :
                (<Labeled><SelectField fullWidth source={`type`} choices={PredicateTypeChoices} label="misc.type" /></Labeled>)}
            {(prType === "and" || prType === "or") ? ( 
                // тут только массив предикатов, минимум 2 значения
                (source) ? (<ArrayField label={false} source={`${source}.prArgs`}>
                    <Datagrid bulkActionButtons={false}>
                        <PredicateShow />
                    </Datagrid>
                </ArrayField>) : (<ArrayField label={false} source={`prArgs`}>
                    <Datagrid bulkActionButtons={false}>
                        <PredicateShow />
                    </Datagrid>
                </ArrayField>)
            ) : null}
            {(prType === "eq" || prType === "notEq") ? ( 
                // тут только 1 поле
                <>
                {(source) ? (<Labeled><TextField fullWidth source={`${source}.args[0]`} label="misc.value"/></Labeled>) : 
                    (<Labeled><TextField fullWidth source={`args[0]`} label="misc.value"/></Labeled>)}
                </>
            ) : null}
            {(prType === "inRange" || prType === "notInRange") ? ( 
                // тут только 2 поля
                <>
                {(source) ? (<Labeled><TextField fullWidth source={`${source}.args[0]`} label="misc.from" /></Labeled>) :
                    (<Labeled><TextField fullWidth source={`args[0]`} label="misc.from" /></Labeled>)}
                {(source) ? (<Labeled><TextField fullWidth source={`${source}.args[1]`} label="misc.to" /></Labeled>) :
                    (<Labeled><TextField fullWidth source={`args[1]`} label="misc.to" /></Labeled>)}
                </>
            ) : null}
    </Box>
}

const Predicate = (props) => {
    const translate = useTranslate();
    //console.log("props: ", props)
    const {
        source,
        ...rest 
    } = props
    const prType = useWatch({ name: `${source}.type` });
    //console.log("prType: ",prType)
    return <Box 
        p='0.2em 0.2em 0.2em 0.2em' 
        border={1} borderColor="gray">
            <Typography variant="body2">{translate('misc.predicate')}</Typography>
            <SelectInput fullWidth source={`${source}.type`} choices={PredicateTypeChoices} label="misc.type" validate={[required()]}/>
            {(prType === "and" || prType === "or") ? ( 
                // тут только массив предикатов, минимум 2 значения
                <ArrayInput label={false} source={`${source}.prArgs`}>
                    <SimpleFormIterator addButton={<AddItemButton label={null}/>} removeButton={<RemoveItemButton label={null}/>}>
                        <Predicate />
                    </SimpleFormIterator>
                </ArrayInput>
            ) : null}
            {(prType === "eq" || prType === "notEq") ? ( 
                // тут только 1 поле
                <TextInput fullWidth source={`${source}.args[0]`} validate={[required()]} label="misc.value"/>
            ) : null}
            {(prType === "inRange" || prType === "notInRange") ? ( 
                // тут только 2 поля
                <>
                <TextInput fullWidth source={`${source}.args[0]`} validate={[required()]} label="misc.from" />
                <TextInput fullWidth source={`${source}.args[1]`} validate={[required()]} label="misc.to" />
                </>
            ) : null}
    </Box>
}

export const OutgoingCriteriaChoices = [
    { id: 'cp', name: 'misc.OutgoingCriteriaChoices.cp' },
    { id: 'mtSource', name: 'misc.OutgoingCriteriaChoices.mtSource' },
    { id: 'mtDest', name: 'misc.OutgoingCriteriaChoices.mtDest' },
];

export const OutgoingRouteToChoices = [
    { id: 'reject', name: 'misc.OutgoingRouteToChoices.reject' },
    { id: 'scList', name: 'misc.OutgoingRouteToChoices.scList' },
    { id: 'scTags', name: 'misc.OutgoingRouteToChoices.scTags' },
];

export const PredicateTypeChoices = [
    { id: 'and', name: 'misc.PredicateTypeChoices.and' },
    { id: 'or', name: 'misc.PredicateTypeChoices.or' },
    { id: 'eq', name: 'misc.PredicateTypeChoices.eq' },
    { id: 'notEq', name: 'misc.PredicateTypeChoices.notEq' },
    { id: 'inRange', name: 'misc.PredicateTypeChoices.inRange' },
    { id: 'notInRange', name: 'misc.PredicateTypeChoices.notInRange' },
];

export const SettingsShow = () => {
    return <Show title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewMpEntities}/>}>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="is_active" />
            <ShowDataElements/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const ShowDataElements = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    //console.log("record: ", record);
    return <>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.settings.section_out')}
            </Typography>
            <Labeled><BooleanField fullWidth label="resources.settings.detectDestMno" source="data.routingSettings.outgoing.detectDestMno" /></Labeled>
            <Labeled><BooleanField fullWidth label="resources.settings.detectSourceMno" source="data.routingSettings.outgoing.detectSourceMno" /></Labeled>
            <ArrayField label="resources.settings.rules" source="data.routingSettings.outgoing.rules">
                <Datagrid bulkActionButtons={false}>
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1">{translate('resources.settings.criteria')}</Typography>
                            <CriteriaArrayField source="criteria"/>
                        </Grid>
                        <Grid item xs={6}>
                            <RouteToField/>
                        </Grid>
                    </Grid>
                </Datagrid>
            </ArrayField>
        </Box>
        <Box p='1em 1em 1em 1em' ></Box>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.settings.section_inc')}
            </Typography>
            <Labeled><BooleanField fullWidth label="resources.settings.detectDestMno" source="data.routingSettings.incoming.detectDestMno" /></Labeled>
            <Labeled><BooleanField fullWidth label="resources.settings.detectSourceMno" source="data.routingSettings.incoming.detectSourceMno" /></Labeled>
            <ArrayField label="resources.settings.rules" source="data.routingSettings.incoming.rules">
                <Datagrid bulkActionButtons={false}>
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="body1">{translate('resources.settings.criteria')}</Typography>
                            <Labeled><BooleanField fullWidth label="resources.settings.anySc" source='criteria.anySc' /></Labeled>
                            <ReferenceArrayField source='criteria.scIds' reference="scs" >
                                <Labeled><SingleFieldList label="resources.scs.menu" linkType={false}>
                                    <ChipField source="name" />
                                </SingleFieldList>
                                </Labeled>
                            </ReferenceArrayField>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{translate('resources.settings.onRuleTrigger')}</Typography>
                            <ReferenceField source='routeToCp' reference="cps" link={false}>
                                <Labeled><TextField label="resources.cps.single" source="name"/></Labeled>
                            </ReferenceField>
                        </Grid>
                    </Grid>
                </Datagrid>
            </ArrayField>
        </Box>
    </>
};

const RouteToField = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return <>
        <Typography variant="body1">{translate('resources.settings.onRuleTrigger')}</Typography>
        <SelectField fullWidth source="routeTo.type" choices={OutgoingRouteToChoices} label="resources.settings.routeTo" />
        {record?.routeTo?.type === "scList" ? (
        <ReferenceArrayField source='routeTo.scIds' reference="scs">
            <Labeled><SingleFieldList fullWidth label="resources.scs.menu" linkType={false}>
                <ChipField source="name" />
            </SingleFieldList>
            </Labeled>
        </ReferenceArrayField> ) : null }
        {record?.routeTo?.type === "scTags" ? 
        <PredicateShow source='routeTo.predicate'/> : null }
    </>
}